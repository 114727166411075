<script>
export default {
  metaInfo() {
    return {
      title: 'Contact us - Europe PMC',
    }
  },
  methods: {
    openFeedbackLink() {
      window.open(
        '/feedback?url=http://europepmc.org/ContactUs',
        'ukpmc_feedback',
        'scrollbars=no,location=no,menuBar=yes,resizable=yes,status=no,toolbar=yes,width=550,height=810'
      )
    },
  },
}
</script>
<template>
  <div id="contact-us-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Contact us</h1>
        <p>
          Europe PMC regards user feedback and opinions as vital to the future
          development of the Europe PMC service. Please contact us with your
          queries or suggestions, using the details below.
        </p>

        <h2>Europe PMC helpdesk</h2>
        <p>
          For help in submitting manuscripts, grant reporting, or using any of
          the other Europe PMC Plus services, contact the Europe PMC helpdesk.
        </p>
        <p>
          Email&nbsp;&nbsp;&nbsp;
          <a href="mailto:helpdesk@europepmc.org">helpdesk@europepmc.org</a
          ><br />
          Phone&nbsp;&nbsp; +44 (0) 1223 494118
        </p>
        <p>Open 9:00 - 17:00 UK time.</p>

        <h2>Europe PMC feedback</h2>
        <p>
          Use the
          <a href="./feedback?url=" @click.prevent="openFeedbackLink"
            >feedback link</a
          >, also available at the bottom of every page, to send us your
          thoughts about the website.
        </p>

        <h2>Follow Europe PMC</h2>
        <p>
          Keep up to date with Europe PMC news. Visit the
          <a href="http://blog.europepmc.org/">Europe PMC blog</a>, the
          <a href="//europepmc.github.io/techblog/">PMC Tech blog</a>, or follow
          <a href="//twitter.com/EuropePMC_news">Europe PMC on twitter</a>.
        </p>

        <h2>Europe PMC Developer Forum</h2>
        <p>
          Questions, issues, and requests regarding Europe PMC APIs can be
          posted to the
          <a
            href="//groups.google.com/a/ebi.ac.uk/forum/#!forum/epmc-webservices"
            >Europe PMC Developer Forum</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#contact-us-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}
</style>
